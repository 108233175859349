import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import {
    Card
} from "reactstrap";
import React from 'react';
import Link from 'next/link';
import contactType from '../../.sourceflow/jobs-Contract Type.json';
import GeoLocation from '/public/site-assets/svg/geo-location.svg';
import Pound from '/public/site-assets/svg/pound.svg';
import Clock from '/public/site-assets/svg/clock.svg';
import styles from './styles.module.scss';

export default function JobCard({
    global,
    pathPrefix,
    post,
    key,
    buttonTitle,
    backgroundColorButton,
}) {

    const contractType = getContractType(post);

    return (
        <>
            <div className={`${styles.root} ps-4 pe-4`} key={key}>
                <Link href={`/jobs/${post.url_slug}/`}>
                    <a className="text-decoration-none h-100 d-block">
                        <Card className="p-4 rounded-4 overflow-hidden h-100 justify-content-between">
                            <div>
                                {post?.title ?
                                    <div className="job-title h6 mb-4">{post?.title}</div> : ""}
                                {post?.location ?
                                    <div className="job-location mb-2"><span className="bg-light d-inline-block ps-5 pt-2 pe-4 pb-2 rounded-3 position-relative"><GeoLocation /><small>{post?.location}</small></span></div> : ""}
                                {post?.salary_package ?
                                    <div className="job-salary-package mb-2"><span className="bg-light d-inline-block ps-5 pt-2 pe-4 pb-2 rounded-3 position-relative"><Pound /><small>{post?.salary_package}</small></span></div> : ""
                                }
                                { }
                                {contractType?.label.en ?
                                    <div className="job-contract-type mb-2"><span className="bg-light d-inline-block ps-5 pt-2 pe-4 pb-2 rounded-3 position-relative"><Clock /><small>{contractType.label.en}</small></span></div> : ""
                                }
                            </div>
                            <div className="text-end mt-4">
                                <span className="primaryBtn" style={{backgroundColor: backgroundColorButton}}>
                                    <SourceFlowText global={global} path={`${pathPrefix}.buttonTitle`}>
                                        {buttonTitle}
                                    </SourceFlowText>
                                </span>
                            </div>
                        </Card>
                    </a>
                </Link>
            </div>
        </>
    )
}
function getContractType(job) {
    const contractId = job.categories.find(cat => (cat.id == contactType.id))?.values?.[0].id
    return contactType.category_values?.find((post) => post.id == contractId)
}
