import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import { Container, Row, Col, Card } from "reactstrap";
import React from "react";
import styles from "./styles.module.scss";
import Link from "next/link";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import JobCard from "../../components/JobCard";
import RightArrow from "/public/site-assets/svg/right-arrow.svg";

export default function LatestJobs({
  global,
  pathPrefix,
  customClass = "",
  heading,
  buttonLink,
  buttonTitle,
  subtitle,
  all_jobs,
  label = true,
  headingFullWidth = false,
  headingTextCenter = false,
  colorCode,
  bgColor,
  buttoncolor,
  editableText = true,
}) {
  const slider = React.useRef(null);
  var settings = {
    autoplay: false,
    arrows: false,
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 6500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    all_jobs.length >= 1 && (
      <section className={`${styles.root} ${customClass}`}>
        <Container>
          <div
            className={`p-4 pt-5 pb-5 p-md-5 rounded-4 primary-bg-color`}
            style={{ backgroundColor: colorCode }}
          >
            {label ? (
              <div className="mb-4">
                <span className="tag pt-1 pb-1 ps-2 pe-2 rounded-2">
                  <small>
                    <SourceFlowText
                      global={global}
                      path={`${pathPrefix}.subtitle`}
                    >
                      {subtitle}
                    </SourceFlowText>
                  </small>
                </span>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex flex-wrap justify-content-between justify-content-lg-between align-items-center">
              <h2
                className={`mb-4 mb-md-0 ${
                  headingFullWidth ? "w-100" : "pe-md-5"
                } ${headingTextCenter ? "text-center" : ""}`}
              >
                {editableText ? (
                  <SourceFlowText
                    global={global}
                    path={`${pathPrefix}.heading`}
                  >
                    {heading}
                  </SourceFlowText>
                ) : (
                  heading
                )}
              </h2>
              {buttonLink ? (
                <Link href={buttonLink}>
                  <a
                    className={`text-decoration-none primaryBtn-outline-white m-0 d-none d-md-inline-block`}
                  >
                    {buttonTitle}
                  </a>
                </Link>
              ) : (
                ""
              )}
            </div>
            <div className="mt-md-4 all-jobs-wrapper">
              <Slider ref={slider} {...settings}>
                {all_jobs?.map((post, index) => (
                  <JobCard
                    key={index}
                    post={post}
                    buttonTitle={`More information`}
                    backgroundColorButton={bgColor}
                    buttonColor={buttoncolor}
                    global={global}
                    pathPrefix={`latest_jobs_widget.job_card`}
                  />
                ))}
              </Slider>
              {all_jobs.length >= 4 && (
                <div className="d-flex flex-nowrap justify-content-between arrows-wrapper mt-5 m-auto">
                  <span
                    className="arrow prev"
                    onClick={() => slider?.current?.slickPrev()}
                  >
                    <RightArrow />
                  </span>
                  <span
                    className="arrow next"
                    onClick={() => slider?.current?.slickNext()}
                  >
                    <RightArrow />
                  </span>
                </div>
              )}
            </div>
            {buttonLink ? (
              <div className="mt-5 d-block d-md-none text-center">
                <Link href={buttonLink}>
                  <a className="text-decoration-none primaryBtn-outline-white m-0">
                    {buttonTitle}
                  </a>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </Container>
      </section>
    )
  );
}
