import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import {
    Container,
    Row,
    Col,
} from "reactstrap";
import styles from './styles.module.scss';
import PhoneIcon from '/public/site-assets/svg/phone.svg';
import BusIcon from '/public/site-assets/svg/bus.svg';
import TeamIcon from '/public/site-assets/svg/team.svg';

import Link from 'next/link';

export default function TextWithCTA({
    global,
    pathPrefix,
    customClass = "",
    link1,
    link2,
    buttonTitle1,
    buttonTitle2,
    column1 = true,
    column2 = true,
    fullWidth = false,
    mainButtonTitle1,
    mainButtonTitle2,
    mainButtonLink1,
    mainButtonLink2
}) {
    return (
        <>
            <section className={`${styles.root} ${customClass}`} >
                <Container>
                    <div className="content-wrapper p-4 ps-lg-5 pe-lg-5 ps-4 pe-4 border rounded-3">
                        <Row className="py-3">
                            {/* {column1 ?
                                <Col md={6} className={`d-flex align-items-start mb-4 mb-md-0 ${fullWidth ? "w-100 d-block" : ""}`}>
                                    <span className="icon me-4"><PhoneIcon /></span>
                                    <Link href={link1}>
                                        <a className="text-decoration-none pe-5 position-relative d-block">
                                            <SourceFlowText global={global} path={`${pathPrefix}.buttonTitle1`}>{buttonTitle1}</SourceFlowText>
                                        </a>
                                    </Link>
                                </Col> : ""} */}
                            {column1 ?
                                <Col md={6} className={`${fullWidth ? "w-100 d-inline-block" : `${column2 ? "mb-md-0" : ""}`}`}>
                                    <div className={`${fullWidth ? "w-100 d-flex flex-wrap flex-md-nowrap justify-content-md-between justify-content-end align-items-center" : ""}`}>
                                        <div className={`${fullWidth ? "d-flex align-items-start" : "d-flex align-items-start"}`}>
                                            <span className="icon me-4"><PhoneIcon /></span>
                                            <Link href={link1}>
                                                <a className="link text-decoration-none pe-5 position-relative d-inline-block">
                                                    <SourceFlowText global={global} path={`${pathPrefix}.buttonTitle1`}>{buttonTitle1}</SourceFlowText>
                                                </a>
                                            </Link>
                                        </div>
                                        {mainButtonTitle1 ?
                                            <div className="mt-4 mt-md-0 ms-3">
                                                <Link href={mainButtonLink1}>
                                                    <a className="linktext-decoration-none primaryBtn m-0 position-relative text-nowrap">
                                                        <SourceFlowText global={global} path={`${pathPrefix}.mainButtonTitle1`}>
                                                            {mainButtonTitle1}
                                                        </SourceFlowText>
                                                    </a>
                                                </Link>
                                            </div> : ""}
                                    </div>
                                </Col> : ""
                            }
                            {column2 ?
                                <Col md={6} className={`${fullWidth ? "w-100 d-inline-block" : ""}`}>
                                    {column1 ?
                                        <hr></hr> : null}
                                    <div className={`${fullWidth ? "w-100 d-flex flex-wrap flex-md-nowrap justify-content-md-between justify-content-end align-items-center" : ""}`}>
                                        <div className={`${fullWidth ? "d-flex align-items-start" : "d-flex align-items-start"}`}>
                                            <span className="icon me-4"><TeamIcon /></span>
                                            <Link href={link2}>
                                                <a className="link text-decoration-none pe-5 position-relative d-inline-block">
                                                    <SourceFlowText global={global} path={`${pathPrefix}.buttonTitle2`}>{buttonTitle2}</SourceFlowText>
                                                </a>
                                            </Link>
                                        </div>
                                        {mainButtonTitle2 ?
                                            <div className="mt-4 mt-md-0 ms-3">
                                                <Link href={mainButtonLink2}>
                                                    <a className="text-decoration-none primaryBtn m-0 position-relative text-nowrap">
                                                        <SourceFlowText global={global} path={`${pathPrefix}.mainButtonTitle2`}>
                                                            {mainButtonTitle2}
                                                        </SourceFlowText>
                                                    </a>
                                                </Link>
                                            </div> : ""}
                                    </div>
                                </Col> : ""
                            }
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    )
}